export const recentlyActivity = [
    {
        id: 1,
        client_name: "Carlos Pelinski",
        type: "Entrega",
        mount: "45000"
    },
    {
        id: 2,
        client_name: "Carlos Pelinski",
        type: "Entrega",
        mount: "45000"
    },
    {
        id: 3,
        client_name: "Carlos Pelinski",
        type: "Entrega",
        mount: "45000"
    },
    {
        id: 4,
        client_name: "Amelia Pelinski",
        type: "Deuda",
        productsCount: 5
    },
    {
        id: 5,
        client_name: "Anastacia Cabrera",
        type: "Deuda",
        productsCount: 3
    },
]